import React, { useEffect, useMemo, useState } from 'react';
import {
  NavLink, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { Button, Empty, Tag } from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import { AnyObject } from '@triare/auth-redux';
import { RequestOptionsType } from '@ant-design/pro-utils/es/typing';
import { createDefaultHiddenColumns, getSorterParams, queryFilterParams } from '../../../utils';
import Table, { listAction } from '../../Common/Table';
import {
  OrderTable,
  useDeliveryServiceGetAll,
  useOrderCopy,
  useOrderDelete,
  useOrderModeOfTransportsGet,
  useOrdersTableGet,
  useOrderStatusesGet,
} from '../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import { dateFormat } from '../../../contstant';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useAuth } from '../../../store/auth';
import { DeliveryServiceList } from './Form/Common/SelectDeliveryService';
import { OrderStatus } from './Adapter/enums';

export enum OrderStatusColor {
  'new' = 'purple',
  'pending' = 'gold',
  'shipped out' = 'cyan',
  'to be paid' = 'geekblue', // TODO update this color

  'declined' = 'default',

  'in progress' = 'orange',

  'processing' = 'blue',

  'rejected' = 'red',

  'on the way' = 'cyan',

  'delivered' = 'green',

  'active' = 'green',
}

export function getOrderStatusColor(status: string): string {
  // @ts-ignore take a color from the list
  return OrderStatusColor[status?.toLowerCase()] || 'rgba(0,0,0,.15)';
}

function ToolBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return [
    <Button
      key="add"
      icon={<PlusCircleOutlined />}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        if (pathname.includes('/companies/')) {
          navigate('../../orders/create');
        } else if (pathname === '/') {
          navigate('orders/create');
        } else {
          navigate('create');
        }
      }}
    >
      Add
    </Button>,
  ];
}

function CopyButton({ record, setTableKey }: { record: OrderTable } & AnyObject) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const orderCopy = useOrderCopy(user?.role === 'user');

  useMessageError([orderCopy]);
  useMessageSuccess([orderCopy], 'Duplicate created successfully');

  useEffect(() => {
    if (!orderCopy.error && !orderCopy.loading && orderCopy.data) {
      navigate(`/orders/${orderCopy.data.id}/edit`);
    }
  }, [orderCopy.data]);

  const ActionButton = listAction.copy;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <ActionButton
      loading={orderCopy.loading}
      title="Copy order"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        orderCopy.fetch({
          id: record.id,
        });
      }}
    />
  );
}

function DeleteButton({ record, setTableKey }: { record: OrderTable } & AnyObject) {
  const { open, contextHolder } = useSimpleModal();
  const { user } = useAuth();
  const orderDelete = useOrderDelete(undefined, user?.role === 'user');

  useMessageError([orderDelete]);
  useMessageSuccess([orderDelete], 'Order deleted successfully');

  useEffect(() => {
    if (!orderDelete.error && !orderDelete.loading && orderDelete.data) {
      setTableKey(Date.now());
    }
  }, [orderDelete.error, orderDelete.loading, orderDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={orderDelete.loading}
        title="Delete order"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete order?',
            content: (
              <span>
                Are you sure you want to delete order
                {' '}
                <b>{record.orderNumber}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => orderDelete.fetch(record.id),
          });
        }}
      />
      {contextHolder}
    </>
  );
}

function Orders({ companyId }: { companyId?: string }): React.ReactNode | null {
  const navigate = useNavigate();
  const { user } = useAuth();
  const ordersGet = useOrdersTableGet(user?.role === 'user');
  const [tableKey, setTableKey] = useState<number>(Date.now());
  const [, setSearchParams] = useSearchParams();
  const orderStatusesGet = useOrderStatusesGet(undefined, false);
  const deliveryServiceGetAll = useDeliveryServiceGetAll(undefined, false);
  const orderModeOfTransportsGet = useOrderModeOfTransportsGet(undefined, false);

  useMessageError([ordersGet, orderStatusesGet, deliveryServiceGetAll, orderModeOfTransportsGet]);

  const columns: ProColumns<OrderTable>[] = useMemo(() => ([
    {
      key: 'orderNumber',
      title: 'Order No.',
      dataIndex: 'orderNumber',
      sorter: true,
      hideInSearch: true,
      render: (text, record) => {
        if (window.location.pathname.includes('/companies/')) {
          return (
            <NavLink to={`../orders/${record.id}`}>{text}</NavLink>
          );
        }

        return (
          <NavLink to={window.location.pathname === '/' ? `orders/${record.id}` : record.id}>{text}</NavLink>
        );
      },
    },
    {
      key: 'createdAt',
      title: 'Date added',
      dataIndex: 'createdAt',
      valueType: 'date',
      sorter: true,
      hideInSearch: true,
      render: (text, record) => (
        record.createdAt?.format(dateFormat)
      ),
    },
    {
      key: 'createdAt',
      title: 'Date added',
      dataIndex: 'createdAt',
      valueType: 'dateRange',
      hideInTable: true,
      search: {
        transform: (value) => (value ? ({
          dateAddedFrom: value[0],
          dateAddedTo: value[1],
        }) : undefined),
      },
    },
    {
      key: 'modeOfTransport',
      title: 'Mode of Transport',
      dataIndex: 'modeOfTransport',
      valueType: 'select',
      request: () => new Promise((resolve) => {
        orderModeOfTransportsGet.fetch().then((data) => resolve(data || [])).catch(() => resolve([]));
      }),
    },
    {
      key: 'deliveryService',
      title: 'Delivery service',
      dataIndex: 'deliveryService',
      valueType: 'select',
      request: () => new Promise((resolve) => {
        deliveryServiceGetAll.fetch().then((data) => resolve((data || []).map(
          (item) => DeliveryServiceList.find(({ value }) => value === item?.value),
        ).filter((value) => !!value) as RequestOptionsType[])).catch(() => resolve([]));
      }),
    },
    {
      key: 'trackingNumber',
      title: 'Tracking No.',
      dataIndex: 'trackingNumber',
      hideInSearch: true,
    },
    {
      key: 'company',
      title: 'Consignee', // Importer Company
      dataIndex: 'importer.company',
      hideInSearch: true,
      hideInTable: user?.role !== 'user',
      render: (_, record) => record?.importer?.company,
    },
    {
      key: 'companyName',
      title: 'Customer', // Company
      dataIndex: 'companyName',
      hideInSearch: true,
      hideInTable: user?.role === 'user',
      render: (_, record) => record.company.companyName,
    },
    {
      key: 'offers',
      title: 'Offers',
      dataIndex: 'offers',
      hideInSearch: true,
      // render: (_, record) => record.company.companyName,
    },
    {
      key: 'invoices',
      title: 'Invoices',
      dataIndex: 'invoices',
      hideInSearch: true,
      // render: (_, record) => record.company.companyName,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      order: 1,
      render: (_, record) => (
        <Tag color={getOrderStatusColor(record.status)}>{record.status}</Tag>
      ),
      valueType: 'select',
      request: () => new Promise((resolve) => {
        orderStatusesGet.fetch()
          .then((data) => resolve(Array.isArray(data) ? data : []))
          .catch(() => resolve([]));
      }),
    },
  ]), [orderStatusesGet, deliveryServiceGetAll, orderModeOfTransportsGet]);

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<OrderTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    if (companyId) {
      Object.assign(newParams, {
        company: companyId,
      });
    }

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return ordersGet.fetch({
      ...newParams,
      tab: undefined,
    }).then((response) => {
      if (response) {
        const { data, total } = response;

        return ({ data: data || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['modeOfTransport'],
  ), []);

  return (
    <Table<OrderTable>
      key={tableKey}
      className="transparent"
      headerTitle="List of orders"
      toolBarRender={ToolBar}
      request={tableRequest}
      columns={columns}
      actions={[
        ['form', ({ text, record }) => ({
          title: 'Edit form',
          onClick: (e: MouseEvent) => {
            e.preventDefault();

            if (window.location.pathname.includes('/companies/')) {
              navigate(`../orders/${record.id}/edit`);
            } else {
              navigate(window.location.pathname === '/' ? `orders/${record.id}/edit` : `${record.id}/edit`);
            }
          },
          className: user?.role === 'user' && record?.status !== OrderStatus.NEW ? 'hidden-element' : undefined,
        })],
        [CopyButton, {
          setTableKey,
        }],
        [DeleteButton, {
          setTableKey,
        }],
      ]}
      search={{
        searchText: 'Filter',
        resetText: 'Clear',
      }}
      options={{
        search: {
          name: 'search',
        },
      }}
      columnsState={{
        persistenceKey: 'pro-table-orders',
        persistenceType: 'localStorage',
        defaultValue: defaultHiddenColumns,
      }}
    />
  );
}

export default Orders;
