import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
} from 'antd';
import * as yup from 'yup';
import {
  Action, AnyObject, refreshToken as refreshTokenAction, RefreshTokenAction,
} from '@triare/auth-redux';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { connect } from 'react-redux';
import { UnknownAction } from 'redux-saga';
import { useAdministratorChangePassword, useUpdateMe } from '../../../../../../hooks/api/users';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import {
  confirmPassword, createRulesForAntd, password,
} from '../../../../../../utils/validations';
import { customizeRequiredMark } from '../../../../../Common/Form/Input/common';
import { useCustomerAccountContext } from '../../customerContext';
import DeleteConfirmModal from '../../../../../Common/Modal/DeleteConfirm';
import { useCompanyDelete } from '../../../../../../hooks/api/company';
import { useAuth } from '../../../../../../store/auth';
import { useCompanyContext } from '../../../../Companies/Form/context';
import store, { RootState } from '../../../../../../store';
import { moduleName } from '../../../../../../store/user';

import styles from '../../index.module.scss';

interface FooterActionProps {
  form: FormInstance;
  isValid?: boolean;
  setValid?: (isValid: boolean) => void;
  onSave: () => void;
  onCancel?: () => void;
  loading: boolean;
}

export function FooterActions({
  form, onSave, onCancel, isValid = true, setValid,
  loading,
}: FooterActionProps) {
  return (
    <div>
      <Button
        type="default"
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          form.resetFields();
          setValid?.(false);
          onCancel?.();
        }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        disabled={!isValid}
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        Save
      </Button>
    </div>
  );
}

export function DeleteButton() {
  const { user } = useAuth();
  const companyDelete = useCompanyDelete(undefined, user?.role === 'user');
  const [modalOpen, setModalOpen] = useState(false);

  useMessageError([companyDelete]);
  useMessageSuccess([companyDelete], 'Customer deleted successfully');
  useEffect(() => {
    if (!companyDelete.loading && !companyDelete.error && companyDelete.data) {
      store.dispatch(signOut());
    }
  }, [companyDelete.loading, companyDelete.error, companyDelete.data]);

  return (
    <>
      <Button
        danger
        loading={companyDelete.loading}
        title="Request account deletion"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        Request deletion
      </Button>
      <DeleteConfirmModal
        okText="Request deletion"
        open={modalOpen}
        handleOpen={setModalOpen}
        fetchHook={companyDelete}
        id={null}
        title="Request account deletion?"
        description="Please confirm your request to delete your account.
          We will process your request promptly,
          securely delete your data, and notify you by email once the process is complete."
      />
    </>
  );
}

export const validationCreateAdministrator = yup.object().shape({
  currentPassword: password,
  newPassword: password,
  confirmPassword,
});

const validationRules = createRulesForAntd(validationCreateAdministrator);

interface FieldType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
interface UpdateUserNameProps {
  contactName: string;
  email: string;
}

type AdminAccountProps = AnyObject

export function AccountUpdateUserName(props : AdminAccountProps): React.ReactNode | null {
  const { form } = useCompanyContext();
  const [formUpdateUserName] = Form.useForm<UpdateUserNameProps>();
  const { companyClient } = useCustomerAccountContext();
  const updateMe = useUpdateMe(); // TODO use this request for user update.

  const [hideButtons, setHiddenButtons] = useState(false);
  const initialValuesUserName = companyClient.data?.admin;
  const [initialValues, setInitialValues] = useState<{ fullName: string }>({ fullName: '' });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    formUpdateUserName.setFieldsValue(companyClient.data?.admin || {});
  }, [companyClient]);

  useEffect(() => {
    setInitialValues({ fullName: companyClient.data?.admin?.fullName || '' });
    formUpdateUserName.setFieldsValue(companyClient.data?.admin || {});
  }, [companyClient]);

  const handleSubmit = () => {
    if (form) {
      const data = formUpdateUserName.getFieldValue('fullName');

      updateMe.fetch({ fullName: data }).then((res) => {
        if (res?.id) {
          setInitialValues({ fullName: res.fullName });
        }
      });
    }
    setHiddenButtons(false);
  };

  const handleFieldsChange = () => {
    const currentValue = formUpdateUserName.getFieldValue('fullName') || '';

    setHiddenButtons(currentValue !== initialValues.fullName);
    setIsValid(currentValue.length >= 3 && currentValue.length <= 250);
  };

  useMessageSuccess([updateMe], 'Changes saved successfully');
  useMessageError([updateMe]);

  return (
    <Form
      form={formUpdateUserName}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={handleFieldsChange}
      initialValues={initialValues}
    >
      <Form.Item
        label="Full name"
        name="fullName"
        rules={[{ required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item label=" " className="form-hidden-after" style={{ display: hideButtons ? 'block' : 'none' }}>
        <div className="form-footer">
          <FooterActions
            form={formUpdateUserName}
            isValid={isValid}
            setValid={setIsValid}
            onSave={handleSubmit}
            onCancel={() => {
              form?.resetFields();
              setHiddenButtons(false);
            }}
            loading={updateMe.loading}
          />
        </div>
      </Form.Item>
      <Divider style={{ marginBottom: '39px' }} />
    </Form>
  );
}

export function AccountUpdatePassword(props: AnyObject): React.ReactNode | null {
  const [formUpdatePassword] = Form.useForm<FieldType>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const administratorChangePassword = useAdministratorChangePassword();

  useMessageError([administratorChangePassword]);
  useMessageSuccess([administratorChangePassword], 'Password updated successfully');
  useEffect(() => {
    if (!administratorChangePassword.loading && !administratorChangePassword.error
      && administratorChangePassword.data) {
      setIsValid(false);
      formUpdatePassword.resetFields();
    }
  }, [administratorChangePassword.loading, administratorChangePassword.error, administratorChangePassword.data]);

  const handleSave = () => {
    if (formUpdatePassword) {
      const { currentPassword, newPassword } = formUpdatePassword.getFieldsValue();

      administratorChangePassword.fetch({
        currentPassword,
        newPassword,
      });
    }
  };

  return (
    <Form
      form={formUpdatePassword}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={() => {
        setIsValid(!(Object.values(formUpdatePassword.getFieldsValue())
          .some((value) => value === '' || value === undefined)
          || formUpdatePassword.getFieldsError().some(({ errors }) => errors.length)));
      }}
      {...props}
    >
      <h3 className={styles.title}>Set a new password</h3>
      <div
        className="description"
      >
        The password must be 12 characters long, including uppercase and lowercase letters, numbers, and a
        <br />
        special symbol.
      </div>

      <Form.Item<FieldType>
        label="Current password"
        name="currentPassword"
        rules={[validationRules]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<FieldType>
        label="New password"
        name="newPassword"
        tooltip={(
          <div>
            <ul>
              <div>Password must contain at least:</div>
              <li>12 characters minimum</li>
              <li>lowercase character</li>
              <li>uppercase character</li>
              <li>number</li>
              <li>special character (!, #, @ etc.)</li>
            </ul>
          </div>
        )}
        rules={[validationRules]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<FieldType>
        label="Confirm new password"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[validationRules, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('New password and Confirm password does not match.');
          },
        })]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label=" " className="form-hidden-after" style={{ display: isValid ? 'block' : 'none' }}>
        <div className="form-footer">
          <FooterActions
            form={formUpdatePassword}
            isValid={isValid}
            setValid={setIsValid}
            onSave={handleSave}
            loading={administratorChangePassword.loading}
          />
        </div>
      </Form.Item>
      <Divider style={{ marginBottom: '39px' }} />
      <h3 className={styles.title}>Delete account</h3>
      <div
        className="description"
      >
        If you need to remove your account, submit a request here.
        {' '}
        {'We\'ll'}
        {' '}
        promptly delete your data securely and in
        <br />
        compliance with regulations.
        We will notify you by email when the account removal process is complete.
      </div>
      <DeleteButton />
    </Form>
  );
}
export function AccountManagement(): React.ReactNode | null {
  return (
    <div>
      <AccountUpdateUserName />
      <AccountUpdatePassword />
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(AccountManagement);
