import React, { useEffect } from 'react';
import { Breadcrumb, Button, Form } from 'antd';
import { NavLink, useSearchParams } from 'react-router-dom';
import OrderForm from '../Form';
import Actions from '../../../Common/Header/Actions';
import { useOrderContextForm } from '../Form/context';
import { useAuth } from '../../../../store/auth';
import { isRoleEnough } from '../../../../enums/user';
import RequestAssistanceButton from '../RequestAssistance';
import { asyncDelay } from '../../../../utils';

export function HeaderActions() {
  const {
    loadingFile,
    // isValid,
    orderCreate,
    orderSave,
    handleNextTab,
    goodsForm,
    orderTypeForm,
  } = useOrderContextForm();
  const { user } = useAuth();
  const goodsLength = (Form.useWatch('goods', goodsForm) || []).length;

  const handleRequestAssistance = async () => {
    orderTypeForm.setFieldValue('isAssistanceNeeded', true);
    await asyncDelay(20);

    return await orderSave()
      .then((res) => {
        if (res) {
          return { orderId: res.id };
        }

        return undefined; // throw new Error('Order save failed');
      });
  };

  return (
    <Actions>
      {user?.role === 'user' && goodsLength ? (
        <RequestAssistanceButton
          loading={orderCreate.loading}
          beforeApiRequest={handleRequestAssistance}
        />
      ) : null}
      <Button
        type={isRoleEnough(user?.role, 'admin') ? 'primary' : 'default'}
        // disabled={!isValid}
        loading={orderCreate.loading || loadingFile}
        onClick={orderSave}
      >
        Save
      </Button>
      {user?.role === 'user' ? (
        <Button
          type="primary"
          loading={orderCreate.loading || loadingFile}
          onClick={handleNextTab}
        >
          Next Step
        </Button>
      ) : null}
    </Actions>
  );
}

export default function OrderCreate(): React.ReactNode | null {
  const [_, setSearchParams] = useSearchParams();

  /** Reset tabs to default on create order page when user reloads page. */
  useEffect(() => {
    setSearchParams({});
  }, []);

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/orders">Orders</NavLink>,
          },
          {
            title: 'Add order',
          },
        ]}
      />

      <OrderForm />
    </>
  );
}
