import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import CompanyForm from '../Form';
import { useCompanyContext } from '../Form/context';
import { useCompanyCreate } from '../../../../hooks/api/company';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';

export function HeaderActions() {
  const navigate = useNavigate();
  const { form, isValid, setInitialState } = useCompanyContext();
  const companyCreate = useCompanyCreate();

  useMessageError([companyCreate]);
  useMessageSuccess([companyCreate], 'Customer added successfully');

  useEffect(() => {
    if (!companyCreate.error && !companyCreate.loading && companyCreate.data) {
      navigate('/companies');
    }
  }, [companyCreate.error, companyCreate.loading, companyCreate.data]);

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={companyCreate.loading}
        onClick={(e) => {
          e.preventDefault();

          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            companyCreate.fetch(data);
          }
        }}
      >
        Save
      </Button>
    </Actions>
  );
}

export default function CompanyCreate(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/companies">Customers</NavLink>,
          },
          {
            title: 'Add customer',
          },
        ]}
      />

      <CompanyForm />
    </>
  );
}
