import { Button } from 'antd';
import { useState } from 'react';
import TermsOfServiceModal from '../../../../Companies/CreateProfile/TermsOfService';

export default function TermsOfService() {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
  };

  return (
    <div>
      <span>
        Review our Terms of Service to understand the rules and guidelines for using our platform.
        Your continued
        <br />
        use of our services constitutes your agreement to these terms.
      </span>
      <div style={{ marginTop: '21px' }}>
        <Button onClick={handleClick}>
          View Terms of Service
        </Button>
      </div>
      {isModalOpen && <TermsOfServiceModal showFooter={false} handleOpen={handleCloseModal} />}
    </div>
  );
}
