import React, { useMemo } from 'react';
import {
  createSearchParams, NavigateFunction, useLocation, useNavigate,
} from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import {
  AppstoreOutlined,
  BankOutlined,
  EnvironmentOutlined,
  FileTextOutlined, KeyOutlined,
  LogoutOutlined, TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { connect } from 'react-redux';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { Route } from '../../../routes';
import store, { RootState } from '../../../store';
import { moduleName, useAuth, User } from '../../../store/auth';
import { doesUserHaveAccess } from '../../../routes/PrivateRoute';
import SignIn from '../../../pages/Auth/SignIn';
import SignUp from '../../../pages/Auth/SignUp';
import Information from '../../../pages/Auth/SignUp/Information';
import ForgotPassword from '../../../pages/Auth/ForgotPassword';
import ResetPassword from '../../../pages/Auth/ResetPassword';
import ConfirmEmail from '../../../pages/Auth/ConfirmEmail';
import Orders from '../../../pages/Orders';
import Companies from '../../../pages/Companies';
import Administrators from '../../../pages/Administrators';
import Account from '../../../pages/Account';
import NotFound from '../../../pages/NotFound';
import OrderCreate from '../../../pages/Orders/Create';
import OrderView from '../../../pages/Orders/View';
import OrderEdit from '../../../pages/Orders/View/Edit';
import CreateCompanyProfile from '../../../pages/Companies/CreateProfile';
import CompanyCreate from '../../../pages/Companies/Create';
import CompanyView from '../../../pages/Companies/View';
import CompanyEdit from '../../../pages/Companies/View/Edit';
import AdministratorCreate from '../../../pages/Administrators/Create';
import AdministratorEdit from '../../../pages/Administrators/View/Edit';
import AddressBook from '../../../pages/AddressBook';
import ParticipantCreate from '../../../pages/AddressBook/Create';
import ParticipantEdit from '../../../pages/AddressBook/View/Edit';
import ParticipantView from '../../../pages/AddressBook/View';
import Services from '../../../pages/Services';
import ServicesCreate from '../../../pages/Services/Create';
import ServiceEdit from '../../../pages/Services/View/Edit';
import ServiceView from '../../../pages/Services/View';
import DefaultServiceEdit from '../../../pages/Services/View/EditDefaultServices';

import styles from './index.module.scss';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

export const routes: MenuRoute[] = [
  {
    name: 'Sign In',
    route: {
      path: 'sign-in',
      element: <SignIn />,
    },
  },
  {
    name: 'Company Sign Up',
    route: {
      path: 'sign-up',
      element: <SignUp />,
    },
    children: [
      {
        name: 'Information',
        route: {
          path: 'information',
          element: <Information />,
        },
      },
    ],
  },
  {
    name: 'Forgot password',
    route: {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
  },
  {
    name: 'Reset password',
    route: {
      path: 'reset-password/:secretKey',
      element: <ResetPassword />,
    },
  },
  {
    name: 'Confirm email',
    route: {
      path: 'complete-registration/:mode/:secretKey',
      element: <ConfirmEmail />,
    },
  },
  {
    privateRoute: true,
    name: 'Create your company profile',
    roles: ['user'],
    route: {
      path: 'create-company-profile',
      element: <CreateCompanyProfile />,
    },
  },
  /** Regular routes \/ */
  {
    privateRoute: true,
    name: 'Orders',
    align: 'left',
    icon: <FileTextOutlined />,
    route: {
      path: 'orders',
      element: <Orders />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Add order',
        route: {
          path: 'create',
          element: <OrderCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'View order',
        route: {
          path: ':id',
          element: <OrderView />,
        },
        children: [
          {
            privateRoute: true,
            name: 'Edit order',
            route: {
              path: 'edit',
              element: <OrderEdit />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    name: 'Address Book',
    align: 'left',
    icon: <EnvironmentOutlined />,
    route: {
      path: 'address-book',
      element: <AddressBook />,
    },
    children: [
      {
        privateRoute: true,
        name: 'Add participant',
        route: {
          path: 'create',
          element: <ParticipantCreate />,
        },
      },
      {
        privateRoute: true,
        name: 'View participant',
        route: {
          path: ':id',
          element: <ParticipantView />,
        },
        children: [
          {
            privateRoute: true,
            name: 'Edit participant',
            route: {
              path: 'edit',
              element: <ParticipantEdit />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    roles: ['admin', 'root'],
    name: 'Services',
    align: 'left',
    icon: <AppstoreOutlined />,
    route: {
      path: 'services',
      element: <Services />,
    },
    children: [
      {
        privateRoute: true,
        roles: ['admin', 'root'],
        name: 'Add service',
        route: {
          path: 'create',
          element: <ServicesCreate />,
        },
      },
      {
        privateRoute: true,
        roles: ['admin', 'root'],
        name: 'Edit default services',
        route: {
          path: 'default-services',
          element: <DefaultServiceEdit />,
        },
      },
      {
        privateRoute: true,
        roles: ['admin', 'root'],
        name: 'View service',
        route: {
          path: ':id',
          element: <ServiceView />,
        },
        children: [
          {
            privateRoute: true,
            roles: ['admin', 'root'],
            name: 'Edit service',
            route: {
              path: 'edit',
              element: <ServiceEdit />,
            },
          },
        ],
      },
    ],
  },

  {
    privateRoute: true,
    roles: ['admin', 'root'],
    name: 'Customers',
    align: 'left',
    icon: <TeamOutlined />,
    route: {
      path: 'companies',
      element: <Companies />,
    },
    children: [
      {
        roles: ['admin', 'root'],
        privateRoute: true,
        name: 'Add customer',
        route: {
          path: 'create',
          element: <CompanyCreate />,
        },
      },
      {
        roles: ['admin', 'root'],
        privateRoute: true,
        name: 'View company',
        route: {
          path: ':id',
          element: <CompanyView />,
        },
        children: [
          {
            roles: ['admin', 'root'],
            privateRoute: true,
            name: 'Edit company',
            route: {
              path: 'edit',
              element: <CompanyEdit />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    roles: ['admin', 'root'],
    name: 'Administrators',
    align: 'left',
    icon: <KeyOutlined />,
    route: {
      path: 'administrators',
      element: <Administrators />,
    },
    children: [
      {
        roles: ['admin', 'root'],
        privateRoute: true,
        name: 'Add admin',
        route: {
          path: 'create',
          element: <AdministratorCreate />,
        },
      },
      {
        roles: ['admin', 'root'],
        privateRoute: true,
        name: 'View admin',
        route: {
          path: ':id',
          element: <NotFound />,
        },
        children: [
          {
            roles: ['admin', 'root'],
            privateRoute: true,
            name: 'Edit admin',
            route: {
              path: 'edit',
              element: <AdministratorEdit />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    align: 'right',
    icon: <UserOutlined style={{ fontSize: 18 }} />,
    name: 'Account',
    route: {
      path: 'account',
      element: <Account />,
    },
  },
  {
    align: 'right',
    icon: <LogoutOutlined />,
    name: 'Log out',
    onClick: () => {
      store.dispatch(signOut());
    },
  },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

const createItem = (navigate: NavigateFunction, {
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User) => {
  // let childrenList;

  // if (children && children.length > 0) {
  //   childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);
  //
  //   if (childrenList.length > 0) {
  //     childrenList = childrenList.map(
  //       ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => {
  //         const item = {
  //           ...child,
  //           label: child.name || child.key,
  //           key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
  //         };
  //
  //         if (child.route) {
  //           item.onClick = () => navigate(`/${child.route?.path}${paramsToString(user, child.route?.path)}`);
  //         }
  //
  //         return item;
  //       },
  //     );
  //   }
  // }

  const name = typeof data.name === 'function' ? data.name(user) : data.name;

  const item = {
    ...data,
    // children: childrenList && childrenList.length > 0 ? childrenList : undefined,
    label: name,
    key: route ? `/${route.path}` : `/${data.key || name}`,
  };

  if (route) {
    item.onClick = () => navigate(`/${route.path}${paramsToString(user, route.params)}`);
  }

  return item;
};

export const menuLeft = routes.filter(({ ignoreMenu }) => ignoreMenu !== true).filter(({ align }) => align === 'left');

export const menuRight = routes
  .filter(({ ignoreMenu }) => ignoreMenu !== true)
  .filter(({ align }) => align === 'right');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length > 0) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return item?.roles ? doesUserHaveAccess(item?.roles, user) : true;
  });
}

export interface MenuProps {
  user: User;
  onlyLogOut?: boolean;
}

function Menu({
  user, onlyLogOut = false,
}: MenuProps): React.ReactNode {
  const navigate = useNavigate();
  const { authorized } = useAuth();
  // const { open, contextHolder } = useSimpleModal();
  const { pathname } = useLocation();
  const menuLeftFiltered = useMemo(
    () => filter(
      /** If menu items provided - render items from props. in other case default */
      (onlyLogOut ? [] : menuLeft).map((data) => createItem(navigate, data, user)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    })).map(({ children, ...item }) => item),
    [user],
  );
  const menuRightFiltered = useMemo(() => {
    const list = filter(
      (onlyLogOut
        ? menuRight.filter((item) => item.name === 'Log out')
        : menuRight).map((data) => createItem(navigate, data, user)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    }));

    // if (list[2] && list[2].children && list[2].children[3]) {
    //   list[2].children[3].onClick = () => {
    //     open({
    //       icon: <LogoutOutlined style={{ color: '#FF4D4F' }} />,
    //       title: 'Log out',
    //       content: 'Are you sure you want to Log out?',
    //       cancelText: 'Cancel',
    //       centered: true,
    //       okText: 'Yes',
    //       onOk: () => {
    //         store.dispatch(signOut());
    //         navigate('/');
    //       },
    //     });
    //   };
    // }

    return list;
  }, [user]);

  const activeMenuItem = `/${pathname.split('/')[1]}` === '/' ? '/orders' : `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        key={`top-${activeMenuItem}`}
        theme="light"
        mode="vertical"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        defaultSelectedKeys={[activeMenuItem]}
        selectedKeys={[activeMenuItem]}
        items={menuLeftFiltered}
      />

      {authorized ? (
        <AntdMenu
          key={`bottom-${activeMenuItem}`}
          theme="light"
          mode="vertical"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          defaultSelectedKeys={[activeMenuItem]}
          selectedKeys={[activeMenuItem]}
          items={menuRightFiltered}
        />
      ) : 'not authorized'}

      {/* {contextHolder} */}
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Menu);
