import React, { useEffect, useMemo } from 'react';
import {
  Form, Input, InputNumber, Select,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import {
  createRulesForAntd, createValidatorTextField,
} from '../../../../../../../utils/validations';
import { ContentProps } from '../index';
import OrderInput from '../../../Common/Input';
import { FormName, useOrderContextForm } from '../../../context';
import { ClientDataPackages } from '../../../../Adapter';
import { Goods } from '../../../../../../../hooks/api/order';
import InputSelectGoodForPackage from '../../../Common/InputSelectGoodForPackage';
import { useAuth } from '../../../../../../../store/auth';
import { isRoleEnough } from '../../../../../../../enums/user';

import styles from '../../../index.module.scss';

interface ReCalcNetValueProps {
  net: number;
  innerPackagingNetUnitaryQuantity: string;
  innerPackagingQuantity: string;
}
export const reCalcNetValue = ({
  net, innerPackagingNetUnitaryQuantity, innerPackagingQuantity,
}: ReCalcNetValueProps) => {
  let value: string | number = net;

  if (innerPackagingNetUnitaryQuantity) {
    value = Number.parseFloat(innerPackagingNetUnitaryQuantity);
  }
  if (innerPackagingQuantity) {
    value = Number.parseFloat((((+value) || 0) * Number.parseFloat(innerPackagingQuantity)).toFixed(3));
  }

  return value;
};

interface ProductFormProps extends ContentProps, FormName {
  item: ClientDataPackages;
}

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);

export default function PackageForm({ formName, item, index }: ProductFormProps) {
  const {
    setValidator, forms: { [formName]: form }, triggerValidationAllForm, generalForm,
  } = useOrderContextForm();

  const { user } = useAuth();
  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  useEffect(() => {
    // !form.getFieldsValue().goods?.some(
    //   (good: Goods) => ['kind'].some(((key) => good && good[key]?.toString().length === 0)), // Remove?.
    // )

    setValidator(formName, () => !(form.getFieldsValue()[formName]
      .some((data: AnyObject) => (data && data.value ? data.value === '' || data.value === undefined : false))
        || form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const userRequiredNumberFields = ['quantity', 'length', 'width', 'height', 'weight', 'value'];
  const validationRules = useMemo(() => createRulesForAntd(yup.object().shape(
    {
      [formName]: yup.array().of(
        yup.object({
          kind: textFieldRequired,
          items: yup.array() // TODO recheck!
            .when([], {
              is: () => !isUserAdmin,
              then: yup.array().required('Required'),
              otherwise: yup.array().notRequired(),
            }),
          ...userRequiredNumberFields.reduce((schema, field) => ({
            ...schema,
            [field]: yup.number()
              .nullable()
              .when([], {
                is: () => !isUserAdmin,
                then: yup.number().required('Required').nullable(),
                otherwise: yup.number().nullable().notRequired(),
              }),
          }), {}),
        }),
      ),
    },
  ), form.getFieldsValue), [formName]);

  /** Sync currency value between forms */
  const currency = Form.useWatch([formName, index, 'currency'], form);
  const insuranceCurrencyWatch = Form.useWatch('insuranceCurrency', generalForm);

  useEffect(() => {
    if (insuranceCurrencyWatch) {
      form.setFieldValue([formName, index, 'currency'], insuranceCurrencyWatch);
    }
  }, [insuranceCurrencyWatch]);

  return (
    <div className={styles.productForm}>
      <h3>
        <span>Package info</span>
      </h3>

      {/* <OrderSelect
        rest={item}
        name={[index, 'kind']}
        label="Package"
        options={enumToOptionsArray(EnumPackageKind)}
      /> */}

      <OrderInput
        rest={item}
        inputNumber
        label="Number of Packages"
        prefix={formName}
        name={[index, 'quantity']}
        rightText="DGA, PI, Delivery"
        inputNumberProps={{
          min: 1,
        }}
        rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
      />

      <Form.Item
        label={isUserAdmin ? 'Length' : (
          <>
            Length
            <span style={{ color: 'red' }}> *</span>
          </>
        )}
        className={styles.sizeLWH}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
      >
        <OrderInput
          rest={item}
          name={[index, 'length']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
        <OrderInput
          rest={item}
          label="Width"
          name={[index, 'width']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
        <OrderInput
          rest={item}
          label="Height"
          name={[index, 'height']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
      </Form.Item>

      <InputSelectGoodForPackage
        item={item}
        formName={formName}
        index={index}
        validationRules={validationRules}
        name={[index, 'items']}
        required={!isUserAdmin}
      />

      <OrderInput
        rest={item}
        label="Weight"
        prefix={formName}
        name={[index, 'weight']}
        inputNumber
        inputNumberProps={{
          suffix: 'kg',
          controls: false,
        }}
        normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
        rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
      />

      <Form.Item name={[index, 'currency']} initialValue="EUR" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item
        {...item}
        label="Value"
        name={[index, 'value']}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        rules={isUserAdmin ? undefined : [validationRules, { required: true, message: <div /> }]}
      >
        <InputNumber
          min={0}
          addonAfter={(
            <Select
              value={currency || 'EUR'}
              onChange={(value) => {
                form.setFieldValue([formName, index, 'currency'], value);
                generalForm.setFieldValue('insuranceCurrency', value); // Sync currency value between forms
                triggerValidationAllForm();
              }}
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          )}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </div>
  );
}
