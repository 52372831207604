import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';

export interface Services extends ServicesCreateParam{
    id: string;
    createdAt: string;
    updatedAt: string;
  }

export interface ServicesCreateParam {
    name: string;
    value: number;
    units: ServiceUnitEnum;
}

export enum ServiceUnitEnum {
  pieces = 'Pcs (pieces)',
  kilograms = 'Kg (kilograms)',
  hours = 'H (hours)'
}

export interface DefaultServices {
  id?: string;
  deliveryMultiplayer: number;
  platformUsageFee: number;
  insuranceFee: number;
}

  interface ServicesGetParams extends PagingParams {
    orderByColumn?: 'name';
  }

export const useServicesGet = <DD = ServicesTableData>(
  decorateData?: (data: PagingDataResponse<Services>) => DD,
): FetchGet<
    PagingDataResponse<Services>,
    ServicesGetParams,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'admin/services',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface ServicesTable extends Services {
    key: string;
  }

export interface ServicesTableData {
    data: ServicesTable[];
    total: number;
  }

export const useServicesTableGet = () => useServicesGet<ServicesTableData>(
  ({ data, meta }: PagingDataResponse<Services>): ServicesTableData => ({
    data: data.map((item: Services): ServicesTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useServicesGetById = <DD = Services>(
  id?: string,
  decorateData?: (data: Services) => DD,
): FetchGetId<
    Services,
    DefaultFetchError,
    unknown,
    DD
  > => useFetchGetId(
    'admin/services',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export const useServicesCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ServicesCreateParam> => (
  useFetchCreate('admin/services')
);

export type ServicesUpdateParams = Services;

export const useServicesUpdate = (id?: string): FetchUpdate<
    FetchSuccess, DefaultFetchError, ServicesUpdateParams> => (
  useFetchUpdate('admin/services', id)
);

export const useServicesDelete = (id?: string): FetchDelete<
    FetchSuccess, DefaultFetchError
  > => (
  useFetchDelete('admin/services', id)
);

export const useDefaultServicesGet = (): FetchGetId<
    DefaultServices,
    DefaultFetchError,
    unknown,
    DefaultServices
  > => useFetchGet('admin/settings');

export const useDefaultServicesUpdate = (): FetchUpdate<
    FetchSuccess, DefaultFetchError, DefaultServices> => (
  useFetchUpdate('admin/settings', '')
);
