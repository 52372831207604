import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { Button, Tag } from 'antd';
import { PlusCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import dayjs from 'dayjs';
import { createDefaultHiddenColumns, getSorterParams, queryFilterParams } from '../../../utils';
import Table, { listAction } from '../../Common/Table';
import {
  useCompaniesTableGet, CompanyTable, useCompanyDelete, CompanyRoleEnum, CompanyStatusEnum,
} from '../../../hooks/api/company';
import { useAuth } from '../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import { dateFormat } from '../../../contstant';
import DeleteConfirmModal from '../../Common/Modal/DeleteConfirm';

import styles from './index.module.scss';

export enum CompanyStatusColor {
  'new' = 'purple',
  'active' = 'green',
  'delete' = 'red',
}

function DeleteButton({ record, setTableKey }: { record: CompanyTable } & AnyObject) {
  const companyDelete = useCompanyDelete();
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  useMessageError([companyDelete]);
  useMessageSuccess([companyDelete], 'Customer deleted successfully');

  useEffect(() => {
    if (!companyDelete.error && !companyDelete.loading && companyDelete.data) {
      setTableKey(Date.now());
    }
  }, [companyDelete.error, companyDelete.loading, companyDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={companyDelete.loading}
        title="Delete customer"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      />
      <DeleteConfirmModal
        open={modalOpen}
        handleOpen={setModalOpen}
        fetchHook={companyDelete}
        id={record.id}
        title="Delete customer?"
        description="All data associated with this account will be permanently deleted. This action cannot be undone."
      />
    </>
  );
}

const columns: ProColumns<CompanyTable>[] = [
  {
    title: 'Company',
    dataIndex: 'companyName',
    key: 'companyName',
    sorter: true,
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <NavLink to={record.id}>{text}</NavLink>
        {record.status === 'delete' ? (
          <WarningOutlined className="warning-icon" />
        ) : null}
      </div>
    ),
    hideInSearch: true,
  },
  {
    title: 'Contact name',
    dataIndex: 'contactName',
    key: 'contactName',
    hideInSearch: true,
  },
  {
    title: 'Mobile number',
    dataIndex: 'phone',
    key: 'phone',
    hideInSearch: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    hideInSearch: true,
  },
  {
    order: 9,
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    valueEnum: CompanyRoleEnum,
  },
  {
    order: 10,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_, record) => (
      <Tag color={getCompanyStatusColor(record.status)}>{record.status}</Tag>
    ),
    hideInSearch: true,
    valueEnum: CompanyStatusEnum,
  },
  // Default hidden columns
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (_, record) => (
      [record.address1, record.address2, record.city, record.state, record.postalCode, record.country]
        .filter((i) => !!i).join(', ')
    ),
    hideInSearch: true,
  },
  {
    title: 'TIN number',
    dataIndex: 'tinNumber',
    key: 'tinNumber',
    hideInSearch: true,
  },
  {
    title: 'Register number',
    dataIndex: 'registerNumber',
    key: 'registerNumber',
    hideInSearch: true,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    hideInSearch: true,
    renderText: (text) => dayjs(text).format(dateFormat),
  },
];

export function getCompanyStatusColor(status: string): string {
  // @ts-ignore take a color from the list
  return CompanyStatusColor[status?.toLowerCase()] || 'rgba(0,0,0,.15)';
}

function ToolBar() {
  const navigate = useNavigate();

  return [
    <Button
      key="add"
      icon={<PlusCircleOutlined />}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        navigate('create');
      }}
    >
      Add
    </Button>,
  ];
}

// Customers
export default function Companies(): React.ReactNode | null {
  const navigate = useNavigate();
  const companiesGet = useCompaniesTableGet();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<CompanyTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    const response = await companiesGet.fetch({
      ...newParams,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([companiesGet]);

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['address', 'tinNumber', 'registerNumber', 'createdAt'],
  ), []);

  return (
    <Table<CompanyTable>
      key={tableKey}
      className="transparent" // styles.table
      headerTitle="List of customers"
      toolBarRender={ToolBar}
      request={tableRequest}
      columns={columns}
      actions={[
        ['edit', ({ record }) => ({
          title: `Edit ${record.companyName}`,
          onClick: (e: MouseEvent) => {
            e.preventDefault();
            navigate(`${record.id}/edit`);
          },
        })],
        [DeleteButton, {
          setTableKey,
        }],
      ]}
      search={{
        searchText: 'Filter',
        resetText: 'Clear',
      }}
      options={{
        search: {
          name: 'search',
        },
      }}
      columnsState={{
        persistenceKey: 'pro-table-companies',
        persistenceType: 'localStorage',
        defaultValue: defaultHiddenColumns,
      }}
    />
  );
}
