import React, { useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrderRequestAssistance } from '../../../../hooks/api/order';
import { useMessageError } from '../../../../hooks/common';

interface RequestAssistanceProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  okText?: React.ReactNode;
  loading?: boolean;
  beforeApiRequest?: () => Promise<{ orderId: string } | undefined>;
  afterApiRequest?: () => Promise<any>;
}

function RequestAssistanceButton({
  okText = 'Send request',
  title = 'Request assistance?',
  description = 'The order and request will be sent to Sephyre. '
  + 'We will process it as soon as possible and contact you with details',
  loading,
  beforeApiRequest,
  afterApiRequest,
}: RequestAssistanceProps) {
  const { id: paramsOrderId = '' } = useParams();
  const navigate = useNavigate();
  const requestAssistance = useOrderRequestAssistance();
  const [open, setOpen] = useState(false);
  const [assistanceNote, setAssistanceNote] = useState('');

  const handleOpen = (val: boolean) => {
    setOpen(val);
  };

  const handleClose = () => {
    setOpen(false);
    setAssistanceNote('');
  };

  const handleOk = async () => {
    try {
      let orderId = '';

      if (beforeApiRequest) {
        try {
          const res = await beforeApiRequest();

          if (res?.orderId) {
            orderId = res.orderId;
          } else {
            // throw new Error('Request failed');
            return;
          }
        } catch {
          // throw new Error('beforeApiRequest failed');
          return;
        }
      } else {
        orderId = paramsOrderId;
      }

      const res = await requestAssistance.fetch({ assistanceNote }, `${orderId}/request-support`);

      if (res?.success) {
        handleClose();

        if (afterApiRequest) {
          await afterApiRequest();
        }

        navigate(`/orders/${orderId}`);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useMessageError([requestAssistance]);

  return (
    <>
      <Button
        type="default"
        loading={loading}
        icon={<QuestionCircleOutlined />}
        onClick={() => handleOpen(true)}
      >
        Request assistance
      </Button>
      <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleOk}
        okText={okText}
        okButtonProps={{
          loading: loading || requestAssistance.loading,
        }}
        width={400}
        destroyOnClose
        centered
      >
        <Alert
          type="warning"
          showIcon
          style={{ background: 'none', border: 'none', padding: '0px' }}
          message={(<b>{title}</b>)}
          description={(
            <div>
              <p>
                {description}
              </p>

              <TextArea
                rows={3}
                placeholder="Indicate any assistance needed (optional)"
                value={assistanceNote}
                onChange={(event) => setAssistanceNote(event.target.value)}
              />
            </div>
          )}
        />
      </Modal>
    </>

  );
}

export default RequestAssistanceButton;
