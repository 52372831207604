import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { ActionType, ProColumns } from '@ant-design/pro-table/es/typing';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import {
  DeleteOutlined, ExclamationCircleFilled, PlusOutlined,
} from '@ant-design/icons';
import { Rule } from 'rc-field-form/lib/interface';
import dayjs from 'dayjs';
import { GoodsTable, useGoodDelete, useGoodsGet } from '../../../../../../hooks/api/order';
import OrderInput, { OrderInputProps } from '../Input';
import { getSorterParams, queryFilterParams } from '../../../../../../utils';
import { FormName, useOrderContextForm } from '../../context';
import { ClientDataGood } from '../../../Adapter';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';

import styles from '../../index.module.scss';

interface InputSelectGoodProps extends OrderInputProps, FormName {
  item: ClientDataGood;
  index: number;
  validationRules: Rule;
}

export default function InputSelectGood({
  item,
  formName,
  index,
  validationRules,
  name,
  inputProps,
  ...props
}: InputSelectGoodProps) {
  const actionRef = useRef<ActionType | undefined>();
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const goodsGet = useGoodsGet();
  const goodDelete = useGoodDelete();
  const { open, contextHolder } = useSimpleModal();

  useEffect(() => {
    if (!goodDelete.error && !goodDelete.loading && goodDelete.response
      && actionRef && actionRef.current && actionRef.current.reloadAndRest
    ) {
      actionRef.current.reloadAndRest();
    }
  }, [goodDelete.data]);

  useMessageError([goodDelete]);
  useMessageSuccess([goodDelete], 'Product deleted successfully');

  const columns: ProColumns<GoodsTable>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Kind of Package',
      dataIndex: 'packagingGroup',
      key: 'packagingGroup',
      width: '200px',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
      align: 'center',
      render: (id, { name: goodName }) => (
        <DeleteOutlined
          style={{ color: 'rgba(0,0,0,.45)', fontSize: '16px' }}
          onClick={(e) => {
            e.stopPropagation();
            open({
              icon: <ExclamationCircleFilled />,
              title: 'Remove product?',
              content: (
                <span>
                  Are you sure you want to remove product
                  {' '}
                  <b>{goodName}</b>
                  {' '}
                  from the list?
                </span>
              ),
              cancelText: 'Cancel',
              okText: 'Remove',
              okButtonProps: {
                danger: true,
              },
              onOk: () => goodDelete.fetch(id as string),
            });
          }}
        />
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelect = ({
    allData: {
      id,
      packaging,
      materialAndCode,
      innerPackagingType,
      segregationGroup,
      // exportLicenseDocument,
      msdsDocument,
      permitDate,
      hsCode,
      ...allData
    },
  }: GoodsTable) => {
    const data = {
      permit: 'free',
    };

    Object.assign(data, allData, {
      saveProductToDatabase: false, /** Rewrite 'save to db', to not duplicate same products on db */
      hsCode: hsCode || null,
      permitDate: permitDate ? dayjs(permitDate) : dayjs(),
      packaging: packaging?.packageId ? {
        label: packaging?.name,
        value: packaging?.packageId,
      } : null,
      materialAndCode: materialAndCode?.packageHomologationId ? {
        value: materialAndCode?.packageHomologationId,
        label: `${materialAndCode?.name} - ${materialAndCode?.code}`,
      } : null,
      innerPackagingType: innerPackagingType?.innerPackageMaterialId ? {
        value: innerPackagingType?.innerPackageMaterialId,
        label: innerPackagingType?.name,
      } : null,
      segregationGroup: segregationGroup?.map((group) => ({
        label: group?.name,
        value: group?.imdgSegregationGroupId,
      })).filter(({ value }) => !!value),
      // exportLicenseDocument: {
      //   file: null,
      //   fileList: [
      //     {
      //       id: exportLicenseDocument?.id,
      //       uid: exportLicenseDocument?.id,
      //       name: exportLicenseDocument?.originalName,
      //       status: 'done',
      //       loading: false,
      //       url: exportLicenseDocument?.location,
      //     },
      //   ],
      // },
      msdsDocument: msdsDocument && msdsDocument?.id ? {
        file: null,
        fileList: [
          {
            id: msdsDocument?.id,
            uid: msdsDocument?.id,
            name: msdsDocument?.originalName,
            status: 'done',
            loading: false,
            url: msdsDocument?.location,
          },
        ],
      } : null,
    });

    Object.keys(data)
      .forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form.setFieldValue([formName, index, key], data[key]);
      });

    handleOk();
    triggerValidationAllForm();
  };

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<GoodsTable>>> => goodsGet.fetch(queryFilterParams({
    page: current ? `${current}` : '1',
    pageSize: pageSize ? `${pageSize}` : '10',
    ...args,
    ...getSorterParams(sorter),
  })).then((response) => {
    if (response) {
      const { data, total } = response;

      return ({ data: data || [], success: true, total });
    }

    return ({ data: [], success: false, total: 0 });
  });

  return (
    <>
      <OrderInput
        className={styles.inputSelectProduct}
        rest={item}
        label="Name"
        prefix={formName}
        name={name}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA"
        inputProps={{
          addonAfter: (
            <Tooltip
              placement="top"
              title="Choose a product from previously saved."
            >
              <PlusOutlined
                onClick={(e) => {
                  e.preventDefault();
                  showModal();
                }}
              />
            </Tooltip>
          ),
          maxLength: 35,
          ...inputProps,
        }}
        {...props}
      />
      <Modal
        centered
        title={(
          <>
            Product Selection
            {' '}
            <span style={{ fontWeight: 'normal', color: 'rgba(0,0,0,.65)' }}>
              (previously filled product fields will be overwritten)
            </span>
          </>
        )}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className={styles.modalTable}
      >
        <ProTable<GoodsTable>
          loading={goodsGet.loading}
          actionRef={actionRef}
          onRow={(record) => ({
            onClick: (event) => {
              event.preventDefault();
              try {
                handleSelect(record);
              } catch (error) {
                console.log(error);
              }
            },
          })}
          columns={columns}
          request={tableRequest}
          search={false}
          rowKey="key"
          headerTitle="List of Products"
          pagination={{
            defaultPageSize: 10,
            size: 'default',
            showQuickJumper: true,
            showTotal: undefined,
          }}
          options={{
            density: false,
            search: {
              name: 'search',
            },
            reloadIcon: false,
            setting: false,
          }}
        />
      </Modal>
      {contextHolder}
    </>
  );
}
